<template>
    <v-card>
        <v-card-title>
            {{ $t('users.headline') }}
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="users"
            :options.sync="options"
            :server-items-length="totalUsersCount"
            :loading="isFetchingUsers"
            :loading-text="$t('users.list.loading')"
            :no-data-text="$t('users.list.noDataFound')"
            :footer-props="{ itemsPerPageOptions: [10, 20, 50] }"
            class="elevation-1 users-list"
            @click:row="userClicked"
        >
            <template #item.email="{ item }">
                <a v-if="item.email" :href="`mailto:${item.email}`">{{ item.email }}</a>
                <span v-else v-t="'common.n/a'" />
            </template>
            <template #item.role="{ item }">
                <span v-html="item.role.join('<br />')" />
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { extractAndDisplayError } from '@/utils'

export default {
    name: 'Users',
    data: function () {
        return {
            options: {},
            headers: [
                { text: this.$t('users.lastName.label'), value: 'lastName' },
                { text: this.$t('users.firstName.label'), value: 'firstName' },
                { text: this.$t('users.email.label'), value: 'email' },
                { text: this.$t('users.role(s).label'), value: 'role', sortable: false }
            ]
        }
    },
    computed: {
        ...mapState('user', ['isFetchingUsers', 'users', 'totalUsersCount'])
    },
    watch: {
        options: {
            handler: function () {
                this.updateData()
            },
            deep: true
        }
    },
    methods: {
        getQueryParams() {
            const searchParams = new URLSearchParams()
            const { sortBy, sortDesc, page, itemsPerPage } = this.options
            const params = {
                page: page - 1,
                size: itemsPerPage || 10
            }
            if (sortBy.length) {
                params.sort = `${sortBy[0]},${sortDesc.length && sortDesc[0] ? 'DESC' : 'ASC'}`
            }

            Object.keys(params).forEach(key => searchParams.set(key, params[key]))

            return searchParams.toString()
        },
        updateData() {
            this.$store
                .dispatch('user/fetchUsers', this.getQueryParams())
                .catch(err => extractAndDisplayError(err, this.$noty))
        },
        userClicked(user) {
            this.$router.push({ name: 'user', params: { id: user.id } })
        }
    }
}
</script>

<style lang="scss">
.users-list tbody tr {
    cursor: pointer;
}
</style>
