<template>
    <v-form ref="form">
        <v-row>
            <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                    v-model="firstName"
                    :label="$t('users.firstName.label')"
                    :rules="rules.firstName"
                    maxlength="255"
                    validate-on-blur
                />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                    v-model="lastName"
                    :label="$t('users.lastName.label')"
                    :rules="rules.lastName"
                    maxlength="255"
                    validate-on-blur
                />
            </v-col>
            <v-col cols="12" md="4" lg="6">
                <v-text-field
                    v-model="email"
                    :label="$t('users.email.label')"
                    :rules="rules.email"
                    maxlength="255"
                    validate-on-blur
                />
            </v-col>
        </v-row>
        <v-divider />
        <h2 v-t="'users.roleAssignments.headline'" class="headline my-4" />
        <template v-for="i in 10">
            <v-row :key="'role-' + i">
                <v-col cols="12" sm="5" md="4" class="py-0">
                    <v-select
                        v-model="getRA(i - 1).roleId"
                        :items="translatedRoles"
                        :label="$t('users.role.label')"
                        :rules="i === 1 ? rules.firstRole : []"
                        clearable
                        validate-on-blur
                    />
                </v-col>
                <v-col
                    v-if="[3, 5].indexOf(getRA(i - 1).roleId) > -1"
                    cols="12"
                    :sm="getRA(i - 1).roleId === 5 ? 2 : 7"
                    md="2"
                    class="py-0"
                >
                    <v-select
                        v-model="getRA(i - 1).responsibilityType"
                        :items="['CHIEF', 'DEPUTY']"
                        :label="$t('users.responsibility.label')"
                        :rules="rules.responsibilityType"
                        validate-on-blur
                    />
                </v-col>
                <v-col
                    v-if="getRA(i - 1).roleId === 4 || getRA(i - 1).roleId === 5"
                    cols="12"
                    :sm="getRA(i - 1).roleId !== 4 ? 5 : 7"
                    :md="getRA(i - 1).roleId !== 4 ? 6 : 8"
                    class="py-0"
                >
                    <v-select
                        v-model="getRA(i - 1).validity.marketSegment"
                        :items="getRA(i - 1).roleId === 4 ? translatedRootSegments : translatedSegments"
                        :label="$t('users.segment.label')"
                        :rules="rules.segment"
                        validate-on-blur
                    />
                </v-col>
                <v-col v-if="getRA(i - 1).roleId === 3" cols="12" md="6" class="py-0">
                    <v-select
                        v-model="getRA(i - 1).validity.productGroup"
                        :items="productGroups"
                        :label="$t('users.productGroup.label')"
                        :rules="rules.productGroup"
                        validate-on-blur
                    />
                </v-col>
            </v-row>
        </template>
    </v-form>
</template>

<script>
import { mapState } from 'vuex'

// eslint-disable-next-line max-len
const rxEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
    name: 'UserForm',
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    data: function () {
        return {
            firstName: '',
            lastName: '',
            email: '',
            roleAssignments: [],
            rules: {
                firstName: [v => !!v || this.$t('users.firstName.errors.required')],
                lastName: [v => !!v || this.$t('users.lastName.errors.required')],
                email: [
                    v => !!v || this.$t('users.email.errors.required'),
                    v => rxEmail.test(v) || this.$t('users.email.errors.format')
                ],
                firstRole: [v => !!v || this.$t('users.role.errors.oneRoleRequired')],
                responsibilityType: [v => !!v || this.$t('users.responsibility.errors.required')],
                segment: [v => !!v || this.$t('users.segment.errors.required')],
                productGroup: [v => !!v || this.$t('users.productGroup.errors.required')]
            }
        }
    },
    computed: {
        ...mapState('product', ['marketSegments', 'productGroups', 'rootSegments']),
        ...mapState('user', ['currentUser', 'roles']),
        translatedSegments() {
            return (this.marketSegments || []).map(seg => {
                return {
                    value: seg.value,
                    text: this.$t(`product.marketSegments.${seg.text}`)
                }
            })
        },
        translatedRootSegments() {
            return (this.rootSegments || []).map(seg => {
                return {
                    value: seg.value,
                    text: this.$t(`product.marketSegments.${seg.text}`)
                }
            })
        },
        translatedRoles() {
            return (this.roles || []).map(r => {
                return {
                    value: r.id,
                    text: r.name
                }
            })
        },
        translatedAssignments() {
            const list = []
            // eslint-disable-next-line array-callback-return
            this.roleAssignments.map(el => {
                const { roleId } = el
                if (roleId) {
                    let scope = 'GLOBAL'
                    let productGroup = null
                    let marketSegment = null
                    switch (roleId) {
                        case 3:
                            scope = 'PRODUCT_GROUP'
                            productGroup = el.validity.productGroup
                            break
                        case 4:
                        case 5:
                            scope = 'MARKET_SEGMENT'
                            marketSegment = el.validity.marketSegment
                            break
                    }
                    list.push({
                        roleId,
                        responsibilityType: el.responsibilityType || 'CHIEF',
                        validity: { scope, productGroup, marketSegment }
                    })
                }
            })
            return list
        }
    },
    watch: {
        value: function () {
            this.initComponentData()
        }
    },
    mounted() {
        this.initComponentData()
    },
    methods: {
        initComponentData() {
            const currentUser = JSON.parse(JSON.stringify(this.currentUser))
            this.firstName = currentUser ? currentUser.firstName : ''
            this.lastName = currentUser ? currentUser.lastName : ''
            this.email = currentUser ? currentUser.email : ''
            this.roleAssignments = currentUser ? currentUser.roleAssignments || [] : []
            while (this.roleAssignments.length < 10) {
                this.roleAssignments.push(this.getEmptyRA())
            }
        },
        getRA(index) {
            return (this.roleAssignments || [])[index] || this.getEmptyRA()
        },
        getEmptyRA() {
            return {
                roleId: null,
                responsibilityType: null,
                validity: {
                    scope: null,
                    marketSegment: null,
                    productGroup: null
                }
            }
        },
        getUserData() {
            return {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                roleAssignments: this.translatedAssignments.filter(el => el.roleId && el.responsibilityType)
            }
        },
        validate() {
            return this.$refs.form.validate()
        }
    }
}
</script>
