<template>
    <v-layout column>
        <v-flex>
            <v-card class="mb-4">
                <v-card-title v-t="'users.createUser.headline'" class="primary--text" />
            </v-card>

            <v-card>
                <v-card-text>
                    <user-form ref="userForm" v-model="userData" />
                </v-card-text>
                <v-card-actions>
                    <v-layout justify-end row>
                        <v-btn
                            v-t="'users.button.create'"
                            :disabled="isSavingUser"
                            :loading="isSavingUser"
                            color="primary"
                            class="mr-4"
                            tile
                            @click="create"
                        />
                        <v-btn v-t="'users.button.cancel'" color="primary" class="mr-8" outlined tile @click="cancel" />
                    </v-layout>
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import { extractAndDisplayError } from '@/utils'
import UserForm from '../components/users/UserForm'

export default {
    name: 'CreateUser',
    components: {
        UserForm
    },
    data: () => ({
        userData: {
            firstName: '',
            lastName: '',
            email: '',
            roleAssignments: []
        }
    }),
    computed: {
        ...mapState('user', ['isSavingUser'])
    },
    watch: {
        userData: {
            handler: function (newValue) {
                this.$store.commit('user/setCreateUserData', newValue)
            },
            deep: true
        }
    },
    beforeDestroy() {
        this.$store.commit('user/setCreateUserData', {})
    },
    mounted() {
        this.$store.dispatch('user/fetchRoles').catch(err => extractAndDisplayError(err, this.$noty))
    },
    methods: {
        create() {
            if (!this.$refs.userForm.validate()) {
                return
            }
            this.$store
                .dispatch('user/createUser', this.$refs.userForm.getUserData())
                .then(user => {
                    this.$noty.success(this.$t('users.createUser.successMessage'), { timeout: 2000 })
                    this.$router.push({ name: 'user', params: { id: user.id } })
                })
                .catch(err => extractAndDisplayError(err, this.$noty))
        },
        cancel() {
            this.userData = {}
            this.$router.push({ name: 'home' })
        }
    }
}
</script>
