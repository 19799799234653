

























import Component from 'vue-class-component'
import Vue from 'vue'
import { mdiDelete } from '@mdi/js'
import { Prop } from 'vue-property-decorator'

@Component
class ModalDeleteUser extends Vue {
    @Prop({ type: Number, required: true })
    readonly id!: number

    @Prop({ type: String, required: true })
    readonly name!: string

    svgDelete = mdiDelete
    isOpen = false

    // noty
    private $noty!: NotyObj

    private async onDeletebutton() {
        this.isOpen = false
        try {
            await this.$store.dispatch('user/deleteUser', this.id)
            this.$noty.success(this.$i18n.t('users.deleteUser.messageSuccess', [this.name]) as string, {
                timeout: 2000
            })
            await this.$router.push({ name: 'users' })
        } catch (e) {
            this.$noty.error(this.$i18n.t('users.deleteUser.messageDelete') + e?.statusText)
            throw e
        }
    }
}

export default ModalDeleteUser
