<template>
    <v-layout column>
        <v-flex>
            <v-card class="mb-4">
                <v-card-title v-t="'users.editUser.headline'" class="primary--text" />
            </v-card>

            <v-card>
                <v-card-text>
                    <user-form ref="userForm" v-model="userData" />
                </v-card-text>
                <v-card-actions>
                    <v-layout justify-end row>
                        <modal-delete-user :id="userData.id" :name="`${userData.firstName} ${userData.lastName}`" />
                        <v-btn
                            v-t="'users.button.save'"
                            :disabled="isSavingUser"
                            :loading="isSavingUser"
                            color="primary"
                            class="mr-4"
                            tile
                            @click="save"
                        />
                        <v-btn
                            v-t="'users.button.cancel'"
                            :disabled="isSavingUser"
                            :loading="isSavingUser"
                            color="primary"
                            class="mr-4"
                            tile
                            outlined
                            @click="cancel"
                        />
                    </v-layout>
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'
import { extractAndDisplayError } from '@/utils'
import UserForm from '@/components/users/UserForm'
import ModalDeleteUser from '@/components/users/ModalDeleteUser'

const onRouted = (to, from, next) => {
    store.dispatch('user/fetchUser', to.params.id).finally(() => next())
}

export default {
    name: 'User',
    components: {
        UserForm,
        ModalDeleteUser
    },
    data: () => ({
        userData: {
            id: null,
            firstName: '',
            lastName: '',
            email: '',
            roleAssignments: []
        },
        originUserData: {
            id: null,
            firstName: '',
            lastName: '',
            email: '',
            roleAssignments: []
        }
    }),
    computed: {
        ...mapState('user', ['currentUser', 'isSavingUser'])
    },
    watch: {
        currentUser: {
            handler: function (newValue) {
                this.userData = JSON.parse(JSON.stringify(newValue))
            },
            immediate: true
        }
    },
    beforeRouteEnter: onRouted,
    beforeRouteUpdate: onRouted,
    beforeDestroy() {
        this.$store.commit('user/clearCurrentUser')
    },
    mounted() {
        this.$store.dispatch('user/fetchRoles').catch(err => extractAndDisplayError(err, this.$noty))
        this.setOriginUserData()
    },
    methods: {
        setOriginUserData() {
            this.originUserData = JSON.parse(JSON.stringify(this.userData))
        },
        save() {
            if (!this.$refs.userForm.validate()) {
                return
            }
            const userData = this.$refs.userForm.getUserData()
            this.$store
                .dispatch('user/updateUser', userData)
                .then(() => {
                    this.setOriginUserData()
                    this.$noty.success(this.$t('users.editUser.successMessage'), { timeout: 2000 })
                })
                .catch(err => extractAndDisplayError(err, this.$noty))
        },
        cancel() {
            this.$store.commit('user/setCurrentUser', this.originUserData)
            setTimeout(() => this.$refs.userForm.validate(), 0)
        }
    }
}
</script>
